<template>
  <div class="background-order" v-if="orderData">
    <section class="section-checkout-detail">
      <b-container>
        <b-breadcrumb class="title-page">
          <b-breadcrumb-item class="size-breadcrumb" active
            ><a href="#/my-order">
              {{ $t('Myorder.header') }}
            </a></b-breadcrumb-item
          >
          <b-breadcrumb-item class="size-breadcrumb text-info">{{
            $t('Myorder.editOrder')
          }}</b-breadcrumb-item>
        </b-breadcrumb>
      </b-container>
      <b-container class="checkout-detail-content mb-4 px-4">
        <div class="pro-feature ck-package py-4">
          <h4>
            {{ $t('booking.product_name') }}：<span>{{
              orderData.packageCode
            }}</span>
          </h4>
          <div class="row mt-3">
            <div class="col-md-6 ck-date">
              <p style="pointer-events: none">
                {{ $t('booking.departure_date') }}：
                <date-picker
                  v-model="orderData.departure"
                  v-if="orderData.departure"
                ></date-picker>
              </p>
            </div>
            <div class="col-md-6 text-md-right">
              <p>
                {{ $t('booking.headcount') }}：
                <input
                  type="number"
                  class="ck_ticket_number"
                  min="1"
                  v-model="orderData.members.length"
                  readonly
                />
              </p>
            </div>
            <div class="col-md-12">
              <hr class="new2" />
            </div>
            <div class="col-md-12">
              <div class="ck-section-2 ck-details">
                <div class="w-75 w-md-50 d-inline-block ck-row">
                  {{ $t('booking.Contact_name') }}:
                </div>
                <div class="form-row mt-3">
                  <div class="form-group col-md-4 form-input-contact-name">
                    <label for="contact_name"
                      >{{ $t('booking.Ch_En')
                      }}<span class="text-danger">* </span></label
                    >
                    <b-input-group>
                      <b-form-select
                        class="booking-phone-prefix"
                        v-model="orderData.contactTitle"
                        :options="optionsTitleNameEng"
                        value-field="value"
                        text-field="text"
                      ></b-form-select>
                      <b-form-input
                        type="text"
                        class="form-control"
                        v-validate="{
                          required: true,
                        }"
                        name="surname"
                        :state="validateState('surname')"
                        v-model="orderData.contactSurname"
                        aria-describedby="input-contact-name-Invalid"
                        placeholder="Surname"
                      />
                      <b-form-input
                        type="text"
                        class="form-control"
                        v-validate="{
                          required: true,
                        }"
                        name="givenName"
                        :state="validateState('givenName')"
                        v-model="orderData.contactGivenname"
                        aria-describedby="input-contact-name-Invalid"
                        placeholder="Given Name"
                      />
                      <b-form-invalid-feedback id="input-contact-name-Invalid">
                        <span class="md-guide">{{
                          $t('register.required')
                        }}</span>
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                  <div
                    class="form-group col-md-4 ck-iput-t2 form-input-contact-phone"
                  >
                    <label for="contact_phone"
                      >{{ $t('booking.contact_phone')
                      }}<span class="text-danger">* </span></label
                    >
                    <b-form-group>
                      <b-input-group>
                        <b-form-select
                          v-model="orderData.prefixPhone"
                          :options="options_phone"
                          class="booking-phone-prefix"
                        ></b-form-select>
                        <b-form-input
                          placeholder=""
                          v-model="orderData.contact"
                          v-validate="{
                            required: true,
                            numeric,
                            min: 8,
                            max: 11,
                          }"
                          style="width: 50%"
                          name="phoneNumber"
                          :state="validateState('phoneNumber')"
                          aria-describedby="input-phoneNumber-Invalid"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-phoneNumber-Invalid">
                          <span class="error">{{
                            $t('register.errorPhoneNumber')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="form-group col-md-4 form-input-contact-email">
                    <label for="contact_email"
                      >{{ $t('booking.Email_address')
                      }}<span class="text-danger">* </span></label
                    >
                    <b-form-group>
                      <b-input-group>
                        <b-form-input
                          class="form-control"
                          v-validate="{
                            required: true,
                            email,
                          }"
                          name="email"
                          :state="validateState('email')"
                          aria-describedby="input-email-Invalid"
                          v-model="orderData.contactEmail"
                          placeholder=""
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-email-Invalid">
                          <span class="error">{{
                            $t('register.errorEmail')
                          }}</span></b-form-invalid-feedback
                        >
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <!-- start: passengers info --->
            <div class="col-md-12 booking-form px-2">
              <div
                v-for="(item, index) in orderData.members"
                :key="index"
                class="ck-section-1 mt-0 mt-md-4 ck-details mb-2"
                :class="[`ck-booking-${index + 1}`]"
              >
                <form
                  :id="`bk-form-${index + 1}`"
                  :class="[`bk-form-${index + 1} background-passenger`]"
                >
                  <div class="w-75 w-md-50 d-inline-block ck-row">
                    {{ $t('booking.passenger')
                    }}<span class="ck-booking-number">{{ index + 1 }} </span>
                    {{ $t('booking.please_fill_in_your_details') }}
                  </div>
                  <div class="form-row mt-3">
                    <div
                      class="form-group col-md-4 ck-iput-3 bk-birthday"
                      :class="[`form-booking-birthday-${index + 1}`]"
                    >
                      <label class="pl-0"
                        >{{ $t('booking.date_of_birth')
                        }}<span class="text-danger">* </span></label
                      >
                      <date-picker
                        format="YYYY-MM-DD"
                        :placeholder="$t('booking.dateFormat')"
                        v-model="item.birthday"
                        class="booking-birthday w-100"
                        @focus="choiseBirthday(item.birthday)"
                        @change="changeBirthDay(item.birthday, index)"
                      ></date-picker>
                      <br />
                    </div>
                    <div
                      class="form-group col-md-4 ck-iput-1"
                      :class="[`form-booking-name-${index + 1}`]"
                    >
                      <label for=""
                        >{{ $t('booking.Ch_En') }}
                        <span class="text-info">*</span></label
                      >
                      <b-input-group>
                        <b-form-select
                          class="booking-phone-prefix"
                          v-model="item.titleEngName"
                          :options="optionsTitleNameEng"
                          value-field="value"
                          text-field="text"
                          :change="changeTitleName(index)"
                        ></b-form-select>
                        <b-form-input
                          type="text"
                          class="form-control"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :name="'surnameEng' + index"
                          :state="validateState('surnameEng' + index)"
                          v-model="item.surnameEng"
                          :aria-describedby="'input-english-Invalid' + index"
                          placeholder="Surname"
                        />
                        <b-form-input
                          type="text"
                          class="form-control"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :name="'givenNameEng' + index"
                          :state="validateState('givenNameEng' + index)"
                          v-model="item.givenNameEng"
                          :aria-describedby="'input-english-Invalid' + index"
                          placeholder="Given Name"
                        />
                        <b-form-invalid-feedback
                          :id="'input-english-Invalid' + index"
                        >
                          <span class="md-guide">{{
                            $t('register.required')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                    <div
                      class="form-group col-md-4 ck-iput-1"
                      :class="[`form-booking-name-eng-${index + 1}`]"
                    >
                      <label for=""
                        >{{ $t('booking.Name_booking') }}
                        <span class="text-info">*</span>
                      </label>
                      <b-input-group>
                        <b-form-select
                          class="booking-phone-prefix"
                          v-model="item.titleChinaName"
                          :options="optionsTitleNameChina"
                          value-field="value"
                          text-field="text"
                        ></b-form-select>
                        <b-form-input
                          type="text"
                          class="form-control"
                          :name="'surnameChina' + index"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :state="validateState('surnameChina' + index)"
                          v-model="item.surnameChina"
                          :aria-describedby="'input-china-Invalid' + index"
                          placeholder="姓"
                        />
                        <b-form-input
                          type="text"
                          class="form-control"
                          :name="'givenNameChina' + index"
                          v-validate="{
                            required: validateName(index),
                          }"
                          :state="validateState('givenNameChina' + index)"
                          v-model="item.givenNameChina"
                          :aria-describedby="'input-china-Invalid' + index"
                          placeholder="名"
                        />
                        <b-form-invalid-feedback
                          :id="'input-china-Invalid' + index"
                        >
                          <span class="md-guide">{{
                            $t('register.required')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <div
                      class="form-group col-md-4 ck-iput-t2 form-input-contact-phone"
                      :class="[`form-booking-phone-${index + 1}`]"
                    >
                      <label for=""
                        >{{ $t('booking.Mobile_number')
                        }}<span class="text-danger">* </span></label
                      >
                      <b-input-group>
                        <b-form-select
                          v-model="item.prefixPhone"
                          :options="options_phone"
                          class="booking-phone-prefix"
                        ></b-form-select>
                        <b-form-input
                          debounce="1000"
                          placeholder=""
                          v-model="item.contact"
                          v-validate="{
                            required: true,
                            numeric,
                            min: 8,
                            max: 11,
                          }"
                          style="width: 50%"
                          :name="'contact' + index"
                          :state="validateState('contact' + index)"
                          :aria-describedby="'input-contact-Invalid' + index"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :id="`input-contact-Invalid` + index"
                        >
                          <span class="error">{{
                            $t('register.errorPhoneNumber')
                          }}</span>
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                    <div
                      class="form-group col-md-4 ck-iput-6"
                      :class="[`form-booking-email-${index + 1}`]"
                    >
                      <label for="">{{ $t('booking.Email_address') }} </label>
                      <input
                        debounce="1000"
                        type="email"
                        class="form-control"
                        name="email[]"
                        v-model="item.email"
                      />
                      <span class="mt-2 pt-2 ck-error"></span>
                    </div>
                  </div>
                  <div class="form-row mt-3">
                    <div class="form-group col-md-3 ck-iput-7">
                      <label>{{ $t('booking.typeOfCertificate') }}</label>
                      <b-form-select
                        v-if="!item.typeOfCertificateOther"
                        v-model="item.typeOfCertificate"
                        :options="optionsTypeOfCertificate"
                        class="booking-phone-prefix"
                        @change="changeCercificate(index)"
                      ></b-form-select>
                      <div class="d-flex" v-else>
                        <b-form-input
                          v-model="item.typeOfCertificate"
                          class="booking-phone-prefix"
                          :placeholder="$t('booking.nameOfCertificate')"
                        ></b-form-input>
                        <b-input-group-prepend>
                          <b-button
                            class="p-1"
                            @click="changeOptionCertificate(index)"
                            >x</b-button
                          >
                        </b-input-group-prepend>
                      </div>
                    </div>
                    <div class="form-group col-md-3 ck-iput-7">
                      <label>{{ $t('booking.placeOfIssue') }}</label>
                      <b-form-select
                        v-model="item.placeOfIssue"
                        :options="optionsPlaceOfIssue"
                        class="booking-phone-prefix"
                      ></b-form-select>
                    </div>
                    <div class="form-group col-md-3 ck-iput-7">
                      <label for="">{{ $t('booking.Passport_number') }} </label>
                      <input
                        type="text"
                        class="form-control"
                        name="passport[]"
                        v-model="item.passportNumber"
                        :id="`booking-passport-${index + 1}`"
                      />
                    </div>
                    <div class="form-group col-md-3 ck-iput-7">
                      <label for="">{{ $t('booking.Date_of_expiry') }} </label>
                      <date-picker
                        format="YYYY-MM-DD"
                        :placeholder="$t('booking.dateFormat')"
                        v-model="item.dateOfExpiry"
                        class="booking-passport-date"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="form-row mt-3 w-100 pointer-events">
                    <table
                      class="table table-bordered table-hover table-responsive table-hover mb-5 ck-table"
                    >
                      <thead>
                        <tr>
                          <th width="7%">{{ $t('booking.Name') }}</th>
                          <th width="8%">
                            {{ $t('booking.Price_per_pax') }}
                          </th>
                          <th width="22%">{{ $t('booking.House_type') }}</th>
                          <th width="6%">{{ $t('booking.Tax') }}</th>
                          <th width="6%">{{ $t('booking.Tips') }}</th>
                          <th width="6%">
                            <div class="tooltip-new">
                              {{ $t('booking.Others') }}
                            </div>
                          </th>
                          <th width="8%">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="`visa-${index + 1}`"
                                :checked="item.visaFee > 0"
                              />
                              <label
                                class="custom-control-label"
                                :for="`visa-${index + 1}`"
                                >{{ $t('booking.Visa') }}</label
                              >
                            </div>
                          </th>
                          <th width="7%">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="`insurance-${index + 1}`"
                                :checked="item.insurance > 0"
                              />
                              <label
                                class="custom-control-label"
                                :for="`insurance-${index + 1}`"
                                >{{ $t('booking.Insurance') }}</label
                              >
                            </div>
                          </th>
                          <th width="7%">
                            {{ $t('booking.promotionDiscount') }}
                          </th>
                          <th width="9%">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="`discount-${index + 1}`"
                                :checked="item.memberDiscount > 0"
                              />
                              <label
                                class="custom-control-label"
                                :for="`discount-${index + 1}`"
                                >{{ $t('booking.memberDiscount') }}</label
                              >
                            </div>
                          </th>
                          <th width="9%">
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="`special-${index + 1}`"
                                :checked="item.specialDiscount > 0"
                              />
                              <label
                                class="custom-control-label"
                                :for="`special-${index + 1}`"
                                >{{ $t('booking.specialDiscount') }}</label
                              >
                            </div>
                          </th>
                          <th width="5%">{{ $t('booking.Sum') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <b-input-group>
                              <b-form-input
                                class="p-2 text-center font12"
                                readonly
                                v-model="item.category"
                              ></b-form-input>
                            </b-input-group>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span>
                            <span
                              class="adultPrice"
                              :id="`departures-memberprice-${index + 1}`"
                              >{{ item.unitPrice }}</span
                            >
                          </td>
                          <td class="text-left px-1 py-4">
                            <b-form-checkbox-group
                              v-model="item.extrabedType"
                              :options="optionsExtrabed"
                              :name="'roomType' + index"
                              stacked
                              ><span
                                >{{ item.extraBedPrice }}
                              </span></b-form-checkbox-group
                            >
                            <b-form-checkbox-group
                              v-model="item.singleRoomType"
                              :options="optionsSingleRoom"
                              :name="'roomType' + index"
                              stacked
                              ><span
                                >{{ item.singleRoomPrice }}
                              </span></b-form-checkbox-group
                            >
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-tax">{{ item.tax }} </span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-tips">{{ item.tip }}</span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-otherPrice">{{ item.other }}</span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-visaFee">{{ item.visaFee }}</span>
                          </td>
                          <td>
                            <span class="currency-symbol">$</span
                            ><span class="bk-insurance">{{
                              item.insurance
                            }}</span>
                          </td>
                          <td>
                            $<span class="bk-visaFee"
                              >{{ item.promotionDiscount }}
                            </span>
                          </td>
                          <td>
                            $<span class="bk-visaFee"
                              >{{ item.memberDiscount }}
                            </span>
                          </td>
                          <td>
                            <span
                              :style="{
                                color:
                                  item.specialDiscount > 0 ? 'red' : 'black',
                              }"
                            >
                              $<span class="bk-visaFee"
                                >{{ item.specialDiscount }}
                              </span>
                            </span>
                          </td>
                          <td>
                            $<span
                              class="bk-amount"
                              :id="`departures-total-${index + 1}`"
                              >{{ item.amount }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
            <b-col cols="12" class="text-right" v-show="userType !== 'USER'">
              <b-button
                type="submit"
                v-if="!busy"
                @click="confirmInput()"
                variant="warning"
                class="my-4 ck-submit btn-warning"
                >{{ $t('booking.confirmInput') }}</b-button
              >
              <b-button v-else variant="warning" disabled>
                <b-spinner small type="grow"></b-spinner>
                {{ $t('Register_and_Login.loading') }}...
              </b-button>
            </b-col>
            <div class="col-md-12">
              <hr class="new2" />
            </div>
            <b-row class="w-100 d-flex justify-content-end">
              <b-col cols="12" md="4">
                <b-row align-v="center">
                  <b-col cols="9" class="text-right pr-0">
                    <div class="total-payment-sum">
                      {{ $t('booking.Grand_total') }}:
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-2">
                    <span class="currency-symbol c-red">$</span>
                    <span class="c-red bk-total-amount-sum">{{
                      orderData.grandTotal
                    }}</span>
                  </b-col>
                  <b-col cols="9" class="text-right pr-0">
                    <div class="total-payment-sum">
                      {{ $t('booking.TIAPaid') }}:
                    </div>
                  </b-col>
                  <b-col cols="3" class="pl-2">
                    <span class="currency-symbol c-red">$</span>
                    <span class="c-red bk-total-amount-sum">{{
                      orderData.tiaLevyPaid
                    }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </section>
  </div>
  <div v-else class="w-100">
    <b-container>
      <Spinner />
    </b-container>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Spinner from '../spinner/Spinner';
import enums from '@/store/enums';
const phone_prefix = enums.phone_prefix;

export default {
  name: 'checkout',
  components: {
    DatePicker,
    Spinner,
  },
  data() {
    return {
      orderData: null,
      options_phone: [
        { value: '+' + phone_prefix.hk, text: '+' + phone_prefix.hk },
        { value: '+' + phone_prefix.mc, text: '+' + phone_prefix.mc },
        { value: '+' + phone_prefix.cn, text: '+' + phone_prefix.cn },
      ],
      lang: this.$language,
      dataOrder: {},
      optionsExtrabed: [{ text: this.$t('booking.Extra_room'), value: true }],
      optionsSingleRoom: [
        { text: this.$t('booking.Single_room'), value: true },
      ],
      busy: false,
      bookedCountCurrent: 0,
      optionsTitleNameEng: [
        { value: 'MR', text: 'MR' },
        { value: 'MRS', text: 'MRS' },
        { value: 'MS', text: 'MS' },
        { value: 'MSTR', text: 'MSTR' },
        { value: 'MISS', text: 'MISS' },
      ],
      optionsTitleNameChina: [
        { value: 'MR', text: '先生' },
        { value: 'MRS', text: '女士' },
        { value: 'MS', text: '小姐' },
        { value: 'MSTR', text: '男童' },
        { value: 'MISS', text: '女童' },
      ],
      optionsTypeOfCertificate: [
        {
          value: null,
          key: 'CN',
          text: this.$t('booking.pleaseSelect'),
          disabled: true,
        },
        { value: 'HRP', key: 'CN', text: this.$t('place_certificate.HRP') },
        { value: 'HKIC', key: 'HK', text: this.$t('place_certificate.HKIC') },
        { value: 'HKSAR', key: 'HK', text: this.$t('place_certificate.HKSAR') },
        { value: 'HKDOI', key: 'HK', text: this.$t('place_certificate.HKDOI') },
        { value: 'MSP', key: 'MO', text: this.$t('place_certificate.MSP') },
        { value: 'MTP', key: 'MO', text: this.$t('place_certificate.MTP') },
        { value: 'TP', key: 'CN', text: this.$t('place_certificate.TP') },
        { value: 'CP', key: 'CN', text: this.$t('place_certificate.CP') },
        { value: 'AU', key: 'AU', text: this.$t('place_certificate.AU') },
        { value: 'CA', key: 'CA', text: this.$t('place_certificate.CA') },
        { value: 'CH', key: 'CH', text: this.$t('place_certificate.CH') },
        { value: 'DE', key: 'DE', text: this.$t('place_certificate.DE') },
        { value: 'FR', key: 'FR', text: this.$t('place_certificate.FR') },
        { value: 'UK', key: 'GB', text: this.$t('place_certificate.UK') },
        { value: 'ID', key: 'ID', text: this.$t('place_certificate.ID') },
        { value: 'JP', key: 'JP', text: this.$t('place_certificate.JP') },
        { value: 'MY', key: 'MY', text: this.$t('place_certificate.MY') },
        { value: 'NL', key: 'NL', text: this.$t('place_certificate.NL') },
        { value: 'PT', key: 'PT', text: this.$t('place_certificate.PT') },
        { value: 'SG', key: 'SG', text: this.$t('place_certificate.SG') },
        { value: 'TH', key: 'TH', text: this.$t('place_certificate.TH') },
        { value: 'TW', key: 'TW', text: this.$t('place_certificate.TW') },
        { value: 'US', key: 'US', text: this.$t('place_certificate.US') },
        { value: 'NZ', key: 'NZ', text: this.$t('place_certificate.NZ') },
        {
          value: 'OTHER',
          key: 'OTHER',
          text: this.$t('place_certificate.OTHER'),
        },
      ],
      optionsPlaceOfIssue: [
        { value: null, text: this.$t('booking.pleaseSelect'), disabled: true },
        { value: 'AF', text: this.$t('place.AF') },
        { value: 'AL', text: this.$t('place.AL') },
        { value: 'DZ', text: this.$t('place.DZ') },
        { value: 'AD', text: this.$t('place.AD') },
        { value: 'AO', text: this.$t('place.AO') },
        { value: 'AI', text: this.$t('place.AI') },
        { value: 'AQ', text: this.$t('place.AQ') },
        { value: 'AG', text: this.$t('place.AG') },
        { value: 'AR', text: this.$t('place.AR') },
        { value: 'AM', text: this.$t('place.AM') },
        { value: 'AW', text: this.$t('place.AW') },
        { value: 'AU', text: this.$t('place.AU') },
        { value: 'AT', text: this.$t('place.AT') },
        { value: 'AZ', text: this.$t('place.AZ') },
        { value: 'BS', text: this.$t('place.BS') },
        { value: 'BH', text: this.$t('place.BH') },
        { value: 'BD', text: this.$t('place.BD') },
        { value: 'BB', text: this.$t('place.BB') },
        { value: 'BY', text: this.$t('place.BY') },
        { value: 'BE', text: this.$t('place.BE') },
        { value: 'BZ', text: this.$t('place.BZ') },
        { value: 'BJ', text: this.$t('place.BJ') },
        { value: 'BM', text: this.$t('place.BM') },
        { value: 'BT', text: this.$t('place.BT') },
        { value: 'BO', text: this.$t('place.BO') },
        { value: 'BA', text: this.$t('place.BA') },
        { value: 'BW', text: this.$t('place.BW') },
        { value: 'BR', text: this.$t('place.BR') },
        { value: 'IO', text: this.$t('place.IO') },
        { value: 'BN', text: this.$t('place.BN') },
        { value: 'BG', text: this.$t('place.BG') },
        { value: 'BF', text: this.$t('place.BF') },
        { value: 'BI', text: this.$t('place.BI') },
        { value: 'KH', text: this.$t('place.KH') },
        { value: 'CM', text: this.$t('place.CM') },
        { value: 'CA', text: this.$t('place.CA') },
        { value: 'CV', text: this.$t('place.CV') },
        { value: 'KY', text: this.$t('place.KY') },
        { value: 'CF', text: this.$t('place.CF') },
        { value: 'TD', text: this.$t('place.TD') },
        { value: 'CL', text: this.$t('place.CL') },
        { value: 'CN', text: this.$t('place.CN') },
        { value: 'CX', text: this.$t('place.CX') },
        { value: 'CC', text: this.$t('place.CC') },
        { value: 'CO', text: this.$t('place.CO') },
        { value: 'KM', text: this.$t('place.KM') },
        { value: 'CG', text: this.$t('place.CG') },
        { value: 'CK', text: this.$t('place.CK') },
        { value: 'CR', text: this.$t('place.CR') },
        { value: 'HR', text: this.$t('place.HR') },
        { value: 'CU', text: this.$t('place.CU') },
        { value: 'CY', text: this.$t('place.CY') },
        { value: 'CZ', text: this.$t('place.CZ') },
        { value: 'DR', text: this.$t('place.DR') },
        { value: 'DK', text: this.$t('place.DK') },
        { value: 'DJ', text: this.$t('place.DJ') },
        { value: 'DM', text: this.$t('place.DM') },
        { value: 'DO', text: this.$t('place.DO') },
        { value: 'ET', text: this.$t('place.ET') },
        { value: 'EC', text: this.$t('place.EC') },
        { value: 'EG', text: this.$t('place.EG') },
        { value: 'SV', text: this.$t('place.SV') },
        { value: 'GQ', text: this.$t('place.GQ') },
        { value: 'ER', text: this.$t('place.ER') },
        { value: 'EE', text: this.$t('place.EE') },
        { value: 'EA', text: this.$t('place.EA') },
        { value: 'FO', text: this.$t('place.FO') },
        { value: 'FK', text: this.$t('place.FK') },
        { value: 'FJ', text: this.$t('place.FJ') },
        { value: 'FI', text: this.$t('place.FI') },
        { value: 'FR', text: this.$t('place.FR') },
        { value: 'GF', text: this.$t('place.GF') },
        { value: 'PF', text: this.$t('place.PF') },
        { value: 'GA', text: this.$t('place.GA') },
        { value: 'GM', text: this.$t('place.GM') },
        { value: 'GE', text: this.$t('place.GE') },
        { value: 'DE', text: this.$t('place.DE') },
        { value: 'GH', text: this.$t('place.GH') },
        { value: 'GI', text: this.$t('place.GI') },
        { value: 'GR', text: this.$t('place.GR') },
        { value: 'GL', text: this.$t('place.GL') },
        { value: 'GD', text: this.$t('place.GD') },
        { value: 'GP', text: this.$t('place.GP') },
        { value: 'GU', text: this.$t('place.GU') },
        { value: 'GT', text: this.$t('place.GT') },
        { value: 'GN', text: this.$t('place.GN') },
        { value: 'GW', text: this.$t('place.GW') },
        { value: 'GY', text: this.$t('place.GY') },
        { value: 'HT', text: this.$t('place.HT') },
        { value: 'HM', text: this.$t('place.HM') },
        { value: 'HK', text: this.$t('place.HK') },
        { value: 'HU', text: this.$t('place.HU') },
        { value: 'IS', text: this.$t('place.IS') },
        { value: 'IN', text: this.$t('place.IN') },
        { value: 'ID', text: this.$t('place.ID') },
        { value: 'IR', text: this.$t('place.IR') },
        { value: 'IQ', text: this.$t('place.IQ') },
        { value: 'IE', text: this.$t('place.IE') },
        { value: 'IL', text: this.$t('place.IL') },
        { value: 'IT', text: this.$t('place.IT') },
        { value: 'IC', text: this.$t('place.IC') },
        { value: 'JM', text: this.$t('place.JM') },
        { value: 'JP', text: this.$t('place.JP') },
        { value: 'JO', text: this.$t('place.JO') },
        { value: 'KZ', text: this.$t('place.KZ') },
        { value: 'KE', text: this.$t('place.KE') },
        { value: 'KI', text: this.$t('place.KI') },
        { value: 'KN', text: this.$t('place.KN') },
        { value: 'KS', text: this.$t('place.KS') },
        { value: 'KW', text: this.$t('place.KW') },
        { value: 'KG', text: this.$t('place.KG') },
        { value: 'LA', text: this.$t('place.LA') },
        { value: 'LV', text: this.$t('place.LV') },
        { value: 'LB', text: this.$t('place.LB') },
        { value: 'LS', text: this.$t('place.LS') },
        { value: 'LR', text: this.$t('place.LR') },
        { value: 'LY', text: this.$t('place.LY') },
        { value: 'LI', text: this.$t('place.LI') },
        { value: 'LT', text: this.$t('place.LT') },
        { value: 'LU', text: this.$t('place.LU') },
        { value: 'MO', text: this.$t('place.MO') },
        { value: 'MK', text: this.$t('place.MK') },
        { value: 'MG', text: this.$t('place.MG') },
        { value: 'MW', text: this.$t('place.MW') },
        { value: 'MY', text: this.$t('place.MY') },
        { value: 'MV', text: this.$t('place.MV') },
        { value: 'ML', text: this.$t('place.ML') },
        { value: 'MT', text: this.$t('place.MT') },
        { value: 'MH', text: this.$t('place.MH') },
        { value: 'MQ', text: this.$t('place.MQ') },
        { value: 'MR', text: this.$t('place.MR') },
        { value: 'MU', text: this.$t('place.MU') },
        { value: 'YT', text: this.$t('place.YT') },
        { value: 'MX', text: this.$t('place.MX') },
        { value: 'FM', text: this.$t('place.FM') },
        { value: 'MD', text: this.$t('place.MD') },
        { value: 'MC', text: this.$t('place.MC') },
        { value: 'MN', text: this.$t('place.MN') },
        { value: 'MS', text: this.$t('place.MS') },
        { value: 'MA', text: this.$t('place.MA') },
        { value: 'MZ', text: this.$t('place.MZ') },
        { value: 'MM', text: this.$t('place.MM') },
        { value: 'NA', text: this.$t('place.NA') },
        { value: 'NR', text: this.$t('place.NR') },
        { value: 'NP', text: this.$t('place.NP') },
        { value: 'NL', text: this.$t('place.NL') },
        { value: 'NS', text: this.$t('place.NS') },
        { value: 'NC', text: this.$t('place.NC') },
        { value: 'NZ', text: this.$t('place.NZ') },
        { value: 'NI', text: this.$t('place.NI') },
        { value: 'NE', text: this.$t('place.NE') },
        { value: 'NG', text: this.$t('place.NG') },
        { value: 'NU', text: this.$t('place.NU') },
        { value: 'NF', text: this.$t('place.NF') },
        { value: 'MP', text: this.$t('place.MP') },
        { value: 'NO', text: this.$t('place.NO') },
        { value: 'OM', text: this.$t('place.OM') },
        { value: 'PK', text: this.$t('place.PK') },
        { value: 'PW', text: this.$t('place.PW') },
        { value: 'PA', text: this.$t('place.PA') },
        { value: 'PG', text: this.$t('place.PG') },
        { value: 'PY', text: this.$t('place.PY') },
        { value: 'PE', text: this.$t('place.PE') },
        { value: 'PH', text: this.$t('place.PH') },
        { value: 'PL', text: this.$t('place.PL') },
        { value: 'PT', text: this.$t('place.PT') },
        { value: 'PR', text: this.$t('place.PR') },
        { value: 'QA', text: this.$t('place.QA') },
        { value: 'RE', text: this.$t('place.RE') },
        { value: 'RO', text: this.$t('place.RO') },
        { value: 'RU', text: this.$t('place.RU') },
        { value: 'RW', text: this.$t('place.RW') },
        { value: 'SA', text: this.$t('place.SA') },
        { value: 'SW', text: this.$t('place.SW') },
        { value: 'SM', text: this.$t('place.SM') },
        { value: 'ST', text: this.$t('place.ST') },
        { value: 'SU', text: this.$t('place.SU') },
        { value: 'SN', text: this.$t('place.SN') },
        { value: 'SC', text: this.$t('place.SC') },
        { value: 'SL', text: this.$t('place.SL') },
        { value: 'SG', text: this.$t('place.SG') },
        { value: 'SK', text: this.$t('place.SK') },
        { value: 'SI', text: this.$t('place.SI') },
        { value: 'SB', text: this.$t('place.SB') },
        { value: 'SO', text: this.$t('place.SO') },
        { value: 'ZA', text: this.$t('place.ZA') },
        { value: 'ES', text: this.$t('place.ES') },
        { value: 'LK', text: this.$t('place.LK') },
        { value: 'KA', text: this.$t('place.KA') },
        { value: 'LC', text: this.$t('place.LC') },
        { value: 'PI', text: this.$t('place.PI') },
        { value: 'VI', text: this.$t('place.VI') },
        { value: 'SD', text: this.$t('place.SD') },
        { value: 'SR', text: this.$t('place.SR') },
        { value: 'SZ', text: this.$t('place.SZ') },
        { value: 'SE', text: this.$t('place.SE') },
        { value: 'CH', text: this.$t('place.CH') },
        { value: 'SY', text: this.$t('place.SY') },
        { value: 'TW', text: this.$t('place.TW') },
        { value: 'TJ', text: this.$t('place.TJ') },
        { value: 'TZ', text: this.$t('place.TZ') },
        { value: 'TH', text: this.$t('place.TH') },
        { value: 'TG', text: this.$t('place.TG') },
        { value: 'TO', text: this.$t('place.TO') },
        { value: 'TT', text: this.$t('place.TT') },
        { value: 'TN', text: this.$t('place.TN') },
        { value: 'TR', text: this.$t('place.TR') },
        { value: 'TM', text: this.$t('place.TM') },
        { value: 'TC', text: this.$t('place.TC') },
        { value: 'TV', text: this.$t('place.TV') },
        { value: 'UG', text: this.$t('place.UG') },
        { value: 'UA', text: this.$t('place.UA') },
        { value: 'AE', text: this.$t('place.AE') },
        { value: 'GB', text: this.$t('place.GB') },
        { value: 'US', text: this.$t('place.US') },
        { value: 'UY', text: this.$t('place.UY') },
        { value: 'UM', text: this.$t('place.UM') },
        { value: 'UZ', text: this.$t('place.UZ') },
        { value: 'VU', text: this.$t('place.VU') },
        { value: 'VA', text: this.$t('place.VA') },
        { value: 'VE', text: this.$t('place.VE') },
        { value: 'VN', text: this.$t('place.VN') },
        { value: 'VK', text: this.$t('place.VK') },
        { value: 'VS', text: this.$t('place.VS') },
        { value: 'WF', text: this.$t('place.WF') },
        { value: 'YE', text: this.$t('place.YE') },
        { value: 'YU', text: this.$t('place.YU') },
        { value: 'ZE', text: this.$t('place.ZE') },
        { value: 'ZM', text: this.$t('place.ZM') },
        { value: 'ZW', text: this.$t('place.ZW') },
      ],
      passengersInfo: null,
      birthdayCurrent: null,
      userType: null,
    };
  },
  mounted() {
    this.userType = this.$store.getters['authModule/getUserType'];
    const id = this.$route.params.order_id;
    this.$store
      .dispatch('orderModule/getOrderById', {
        id: id,
      })
      .then((data) => {
        this.orderData = data;
        this.passengersInfo = this.orderData.members;
        this.orderData.departure = new Date(this.orderData.departure);
        let textSearch = 0;
        this.passengersInfo.forEach((member) => {
          member.birthday = new Date(member.birthday);
          if (member.dateOfExpiry) {
            member.dateOfExpiry = new Date(member.dateOfExpiry);
          }
        });
        this.options_phone.forEach((item) => {
          textSearch = this.orderData.contactPhone.indexOf(item.text);
          if (textSearch != -1) {
            this.orderData.prefixPhone = item.text;
            this.orderData.contact = this.orderData.contactPhone.replace(
              item.text,
              '',
            );
            return;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    validateName(index) {
      let surnameEng = this.passengersInfo[index].surnameEng;
      let givenNameEng = this.passengersInfo[index].givenNameEng;
      let surnameChina = this.passengersInfo[index].surnameChina;
      let givenNameChina = this.passengersInfo[index].givenNameChina;
      if ((surnameEng && givenNameEng) || (surnameChina && givenNameChina)) {
        return false;
      }
      return true;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    confirmInput: function () {
      this.busy = true;
      const orderSubmit = {
        id: this.orderData._id,
        members: this.orderData.members,
        createdAt: this.orderData.createdAt,
        contactTitle: this.orderData.contactTitle,
        contactSurname: this.orderData.contactSurname,
        contactGivenname: this.orderData.contactGivenname,
        contactEmail: this.orderData.contactEmail,
        contactPhone: this.orderData.prefixPhone + this.orderData.contact,
      };
      this.$store
        .dispatch('orderModule/editInfotOrder', orderSubmit)
        .then((data) => {
          setTimeout(function () {
            const paymentUrl = window.location.origin + '/#/my-order';
            window.location.assign(paymentUrl);
          }, 1500);
        })
        .catch((error) => {
          this.$vToastify.error({
            body: this.$t('error'),
            defaultTitle: false,
          });
          this.busy = false;
        });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    calculateAge: function (birthdate) {
      const today = new Date();
      const age =
        today.getFullYear() -
        birthdate.getFullYear() -
        (today.getMonth() < birthdate.getMonth() ||
          (today.getMonth() === birthdate.getMonth() &&
            today.getDate() < birthdate.getDate()));
      return age;
    },
    choiseBirthday: function (date) {
      this.birthdayCurrent = date;
    },
    changeBirthDay: function (date, index) {
      if (!date) {
        this.passengersInfo[index].birthday = this.birthdayCurrent;
        return;
      }
      const age = this.calculateAge(date);
      let categoryCurrent = '';
      if (age > 11) {
        //setting category
        categoryCurrent = 'Adult';
      } else {
        //setting category
        if (age >= 2) {
          //setting category
          categoryCurrent = 'Children';
        } else {
          //setting category
          categoryCurrent = 'Infant';
        }
      }
      if (categoryCurrent !== this.passengersInfo[index].category) {
        this.passengersInfo[index].birthday = this.birthdayCurrent;
        this.$vToastify.error({
          body: this.$t('Myorder.changeDateError'),
          defaultTitle: false,
        });
      }
    },

    changeTitleName: function (index) {
      let passenger = this.passengersInfo[index];
      passenger.titleChinaName = this.optionsTitleNameChina.filter((obj) => {
        return obj.value === passenger.titleEngName;
      })[0].value;
    },

    changeCercificate: function (index) {
      let passenger = this.passengersInfo[index];
      if (passenger.typeOfCertificate === 'OTHER') {
        passenger.typeOfCertificate = '';
        passenger.typeOfCertificateOther = true;
        return;
      }
      passenger.placeOfIssue = this.optionsTypeOfCertificate.filter((obj) => {
        return obj.value === passenger.typeOfCertificate;
      })[0].key;
    },

    changeOptionCertificate: function (index) {
      let passenger = this.passengersInfo[index];
      passenger.typeOfCertificateOther = false;
      passenger.typeOfCertificate = null;
      passenger.placeOfIssue = null;
    },
  },
};
</script>

<style type="text/css" scoped>
.text-size {
  font-size: 0.95rem;
}

.background-order {
  background-color: #f4f5fd;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #000000;
  content: '>';
}

.span-amount {
  font-size: 1.425rem;
}

.size-breadcrumb {
  font-weight: 500;
  font-size: 1.6625rem;
}

.title-page {
  height: 70px;
  padding-top: 32px;
}

.font12 {
  font-size: 12px;
}

.pointer-events {
  pointer-events: none;
}

.title-stitle {
  border-left: 8px solid #006bba;
  padding-left: 10px;
}

.background-passenger {
  background-color: #e0eaeb47;
  padding: 10px 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.checkout-detail-content {
  background-color: #fff;
}
.form-input-contact-phone label,
.form-input-contact-phone span.mt-2.pt-2.ck-error {
  margin-left: 0 !important;
}
.booking-phone-prefix {
  height: calc(1.18em + 1.06rem + 1px);
  border: 1px solid rgb(209, 210, 219);
  box-shadow: unset !important;
  padding: 0px 5px;
}
table.ck-table thead tr {
  border-top: 3px solid #1997f8;
}
table.ck-table thead th {
  color: #1997f8;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
table.ck-table tbody td {
  text-align: center;
  font-size: 14px;
}
.ck-section-1.p-4 {
  background-color: #f3f6f8;
  margin-bottom: 1rem;
}
.ck-section-2 {
  margin-bottom: 1rem;
}
hr.new2 {
  border-top: 1px dashed #999999;
}
.form-group.col-md-2.ck-iput-2 {
  flex: 0 0 35%;
  max-width: 35%;
}
.form-group.col-md-6.ck-iput-7 {
  flex: 0 0 52.5%;
  max-width: 52.5%;
}
.ck-package h4 > span {
  color: #2296df;
}
.ck-date p {
  color: #333333;
}
.ck-package h4 {
  font-size: 1.2rem;
  color: #333333;
  font-weight: 400;
}
.ck_ticket_number {
  line-height: 2;
  height: 1.6rem;
  width: 4rem;
  border-color: rgb(122 123 151 / 50%);
  text-align: center;
}
.ck-up-number,
.ck-down-number {
  width: 1.8rem;
  background-color: #f6a604;
  color: #fff;
  border: 1px solid;
}
.ck-row {
  color: #2296df;
  font-size: 1.2rem;
}
.ck-row a {
  color: #2296df;
}

.ck-details input,
.ck-details select {
  border-radius: unset;
  height: calc(1.1em + 1.06rem + 2px);
}

.ck-details textarea {
  border-radius: unset;
}
.ck-details .form-group.col-md-5.ck-iput-2 input {
  width: 90%;
}
.ck-details .form-group.col-md-3.ck-iput-3 input {
  width: 240px;
}
.ck-details label > span {
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1.5px;
}
.ck-details .ck-iput-4 select {
  float: right;
}

table.ck-table {
  background-color: #fff;
}
table.ck-table thead th {
  background-color: #fff;
}

.bv-no-focus-ring {
  display: inline-flex;
}

.bv-no-focus-ring > span {
  color: #f66c4b;
  font-weight: 400;
  font-size: 1rem;
  padding-left: 5px;
}

.c-red {
  color: #f66c4b;
}
.ck-iput-t3 input,
.ck-iput-t3 label,
.ck-iput-t3 span.ck-error {
  margin-left: 4.2rem;
}
.ck-iput-t2 input,
.ck-iput-t2 label,
.ck-iput-t2 span.ck-error {
  margin-left: 2rem;
}
.ck-error {
  color: #f66c4b;
  font-size: 0.88rem;
  /*letter-spacing: 1.5px;*/
  /*line-height: 3;*/
}
.ck-s3-radio-text label {
  color: #2296df;
  width: 260px;
}
.ck-s3-radio-text label > span {
  color: #f6a604;
  font-weight: unset;
}
.ck-payment-method .custom-radio label {
  color: #2296df;
  padding: 0.1rem 3rem;
  width: 260px;
  text-align: center;
  margin-top: 10px;
  border: 1px solid #2296df;
}
.ck-payment-method span {
  margin-left: 21rem;
}
button.ck-submit {
  background-color: #f6a604;
  border: 1px solid #f6a604;
}
.ck-date .datepicker-popup {
  display: none;
}
.ck-details .departures-price {
  width: 100%;
}
.total-payment-sum {
  font-size: 1.1rem;
  color: #333333;
  font-weight: 400;
}

.col-md-2.bk-col-submit {
  flex: 0 0 18.5%;
  max-width: 18.5%;
}
.datepicker input {
  height: calc(1.2em + 1.06rem + 2px);
}
/* Tooltip container */
.tooltip-new {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black;  If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip-new .tooltiptext {
  visibility: hidden;
  min-width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-new:hover .tooltiptext {
  visibility: visible;
}

.tooltip-new .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.ck-distance span {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}
#modal-waring-bk .modal-content {
  border-radius: unset;
  border: unset;
}
header.header-global {
  background: #fff;
}

@media (min-width: 768px) {
  .form-group.col-md-2.ck-iput-1,
  .form-group.col-md-3.ck-iput-5,
  .form-group.col-md-3.ck-iput-6 {
    flex: 0 0 19%;
    max-width: 19%;
  }

  .form-group.col-md-2.ck-iput-4 {
    flex: 0 0 11%;
    max-width: 11%;
  }

  .form-group.col-md-2.ck-iput-2 {
    flex: 0 0 42%;
    max-width: 42%;
  }

  .ck-details .ck-iput-4 label {
    margin-left: 20px;
  }

  .ck-s3-radio-text label {
    width: auto;
  }
}
</style>
